<template>
  <el-row style="margin-top: 100px">
    <el-col :span="6" :offset="9">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>注册</span>
        </div>
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input show-password v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="repassword">
            <el-input show-password v-model="form.repassword"></el-input>
          </el-form-item>
          <el-form-item label="部门" prop="department_id">
            <el-select v-model="form.department_id" placeholder="全部" clearable style="width: 100%">
              <el-option v-for="item in options.department" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="真实姓名" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="邀请码" prop="name">
            <el-input v-model="form.code"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('form')">注册</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "Register",
  created() {
    let me = this;
    this.axios.get('/api/department/list').then(res => {
      res.forEach(val => {
        me.options.department.push(val);

      })
    })
  },
  data() {
    return {
      options: {
        department: []
      },
      form: {
        username: '',
        password: '',
        repassword: '',
        department_id: null,
        name: '',
        code:''
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        repassword: [
          {required: true, message: '请输入确认密码', trigger: 'blur'},
        ],
        department_id: [
          {required: true, message: '请选择部门', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入真实名称', trigger: 'blur'},
        ],
        code:[
          {required: true, message: '请输入邀请码', trigger: 'blur'},
        ]
      }
    }
  },
  methods: {
    onSubmit: function (name) {
      let me = this;
      me.$refs[name].validate(valid => {
        if (valid) {
          let params = me.form;
          me.axios.post('/api/user/register', params).then(() => {
            me.$router.push('/login');
          });
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>